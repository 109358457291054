/* eslint-disable react/jsx-newline */
import React, { useState } from 'react'

import logoNormandie from '@assets/svg/logo-region-normandie.svg'
import logoUE from '@assets/svg/logo-ue-fonds-region.svg'
import { Box, Link as ChakraUiLink, Divider, Image, Text } from '@chakra-ui/react'
import { useMediaQuery } from '@chakra-ui/react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import IconLink from '@components/ui/IconLink'
import Link from '@components/ui/Link'
import { device } from '@helpers/mediaQueryBreakpoints'
import { FACEBOOK_URL, LKD_URL } from '@helpers/url'
import { navigate } from 'gatsby'
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'

import Logo from '../Logo'
import Section from '../Section'
import CollapsibleNav from './CollapsibleNav'
import links from './dataNav.json'

const Footer = () => {
    const [isMobile] = useMediaQuery(device.xs)
    const [isTablet] = useMediaQuery(device.tb)

    const [isLegalNoticeModalOpen, setLegalNoticeModalOpen] = useState(false)
    const [isCreditsModalOpen, setCreditsModalOpen] = useState(false)
    const [isPrivacyPolicyModalOpen, setPrivacyPolicyModalOpen] = useState(false)

    return (
        <Section
            bg="white"
            itemScope
            itemType="https://www.schema.org/SiteNavigationElement"
            footer={
                <>
                    {isTablet &&
                        links.map(({ id, title, dataNav }) => (
                            <CollapsibleNav key={id} title={title} dataNav={dataNav} />
                        ))}

                    <Box bg="indigo.100" display="flex" alignItems="center" justifyContent="center" py="1.5">
                        <ChakraUiLink
                            itemProp="url"
                            fontSize="xs"
                            color="indigo.500"
                            onClick={() => setLegalNoticeModalOpen(true)}
                        >
                            Mentions légales
                        </ChakraUiLink>

                        <Divider
                            orientation="vertical"
                            mx="2"
                            borderColor="indigo.500"
                            borderWidth="1px"
                            height="2.5"
                        />

                        <ChakraUiLink
                            itemProp="url"
                            fontSize="xs"
                            color="indigo.500"
                            onClick={() => setCreditsModalOpen(true)}
                        >
                            Crédits
                        </ChakraUiLink>

                        <Divider
                            orientation="vertical"
                            mx="2"
                            borderColor="indigo.500"
                            borderWidth="1px"
                            height="2.5"
                        />

                        <ChakraUiLink
                            itemProp="url"
                            fontSize="xs"
                            color="indigo.500"
                            onClick={() => navigate('/privacyPolicy')}
                        >
                            Politique de confidentialité
                        </ChakraUiLink>
                    </Box>

                    <Modal isCentered isOpen={isLegalNoticeModalOpen} onClose={() => setLegalNoticeModalOpen(false)}>
                        <ModalOverlay />

                        <ModalContent>
                            <ModalHeader>
                                <Text fontWeight="bold" color="indigo.500">
                                    Mentions légales
                                </Text>
                            </ModalHeader>

                            <ModalCloseButton />

                            <ModalBody>
                                <Text color="indigo.500" fontSize="sm" mb="6">
                                    Le site Staffea.com est édité par la société LIMENGO, SAS au capital social de 37
                                    500 €
                                    <br />
                                    <br />
                                    Campus Effiscience – 8, rue Léopold Sédar Senghor – 14460 Colombelles -
                                    contact@staffea.com
                                    <br />
                                    <br />
                                    Directeur de la publication : <Text variant="bold">Mathieu Mosquet</Text>
                                    <br />
                                    <br /> Le site Staffea.com est hébergé par OVH <br />
                                    <br /> 2, rue Kellermann – 59100 Roubaix – France
                                </Text>
                            </ModalBody>
                        </ModalContent>
                    </Modal>

                    <Modal isCentered isOpen={isCreditsModalOpen} onClose={() => setCreditsModalOpen(false)}>
                        <ModalOverlay />

                        <ModalContent>
                            <ModalHeader>
                                <Text fontWeight="bold" color="indigo.500">
                                    Crédits
                                </Text>
                            </ModalHeader>

                            <ModalCloseButton />

                            <ModalBody>
                                <Text color="indigo.500" fontSize="sm" mb="6">
                                    <Text variant="bold" display="block">
                                        © STAFFEA 2024
                                    </Text>
                                    <br />
                                    Le site Staffea.com a été développé par la société LIMENGO.
                                    <br />
                                    La charte graphique a été réalisée par{' '}
                                    <Text variant="bold">Alexandre Andurand.</Text> <br />
                                    <ChakraUiLink color="pink.500" href="https://www.alexandre-andurand.com/">
                                        www.alexandre-andurand.com
                                    </ChakraUiLink>
                                </Text>
                            </ModalBody>
                        </ModalContent>
                    </Modal>

                    <Modal
                        isCentered
                        isOpen={isPrivacyPolicyModalOpen}
                        onClose={() => setPrivacyPolicyModalOpen(false)}
                    >
                        <ModalOverlay />
                    </Modal>
                </>
            }
        >
            <Box
                pt="8"
                pb="5"
                width="100%"
                gap="10"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="start"
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="start"
                    width={!isTablet ? '40%' : '100%'}
                    gap={10}
                >
                    <Box
                        display="flex"
                        flexDirection={!isTablet || isMobile ? 'column' : 'row'}
                        justifyContent="flex-start"
                        alignItems="start"
                        gap={!isTablet || isMobile ? 0 : 7}
                    >
                        <Box display="flex" flexDirection="column" width="100%">
                            <Logo margin="0 0 15px" width="150px" />

                            <Text fontSize="xs" color="indigo.500">
                                ©Limengo
                            </Text>

                            <Text fontSize="xs" color="indigo.500">
                                8 rue Léopold Sédar-Senghor
                            </Text>

                            <Text fontSize="xs" color="indigo.500">
                                14460 Colombelles
                            </Text>
                        </Box>

                        {isTablet && !isMobile && (
                            <Divider
                                borderColor="indigo.300"
                                height="125px"
                                orientation="vertical"
                                borderWidth="2"
                                width={3}
                            />
                        )}

                        <Box
                            display="flex"
                            gap={3}
                            alignItems="center"
                            justifyContent="center"
                            flexDirection={!isTablet || isMobile ? 'row' : 'column'}
                            height="100"
                        >
                            <IconLink
                                text=""
                                isExternalLink
                                to={FACEBOOK_URL}
                                boxSize={7}
                                icon={FaFacebookSquare}
                                color="indigo.500"
                                hoverColor="green.500"
                            />

                            <IconLink
                                to={LKD_URL}
                                text=""
                                isExternalLink
                                boxSize={7}
                                icon={FaLinkedin}
                                color="indigo.500"
                                hoverColor="green.500"
                            />
                        </Box>

                        {isTablet && !isMobile && (
                            <Divider
                                borderColor="indigo.300"
                                height="125px"
                                orientation="vertical"
                                borderWidth="2"
                                width={3}
                            />
                        )}
                    </Box>

                    <Box display="flex" flexDirection={'column'}>
                        <Text fontSize="sm" fontWeight="bold" color="indigo.500">
                            Made In Normandie
                        </Text>

                        <Box display="flex" flexDirection={!isTablet || isMobile ? 'column' : 'row'}>
                            <Image src={logoNormandie} alt="Logo Région Normandie" objectFit="contain" width={90} />

                            <Image
                                src={logoUE}
                                alt="Logo Union Européenne fonds de développement régional"
                                objectFit="contain"
                                width={90}
                            />
                        </Box>
                    </Box>
                </Box>

                {!isTablet && (
                    <Box display="flex" gap={10} justifyContent="flex-end" width="60%">
                        {links.map(({ id, title, dataNav }) => (
                            <Box key={id.toString() + title} margin="auto" marginTop="unset">
                                <Text fontSize="sm" fontWeight="bold" color="indigo.500" mb="1.5">
                                    {title}
                                </Text>

                                {dataNav.map(function ({ subTitle, link }, index) {
                                    return (
                                        <Box itemProp="name" key={index.toString() + subTitle} mb="1.5">
                                            <Link itemProp="url" to={link} fontSize="xs" color="indigo.500">
                                                {subTitle}
                                            </Link>
                                        </Box>
                                    )
                                })}
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
        </Section>
    )
}

export default Footer
